import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
    private subject = new BehaviorSubject<any | null>(null)
    constructor() { }

    public getData() {
        return this.subject.asObservable() 
    }

    public open($event:any, component:string,  options?:any, eventEmitter?:EventEmitter<any> | null) {
        if($event) $event.preventDefault();
        const option = {
            component: component,
            optParam: options,
            eventEmitter: eventEmitter
        }

        this.subject.next(option);
    }

    public closeAll() {
        this.subject.next(null)
    }
}
